<template>
  <b-row class="match-height align-items-center">
    <b-col>
      <b-form-group
        label="Mamül Kodu"
        label-for="pcode"
      >
        <b-form-input
          id="pcode"
          v-model="filterParams.pcode"
          v-b-tooltip.hover
          title="Mamül kodun tamamınız yazmanız gerekmez. Örneğin 4 hanesini yazmanız yeterlidir."
          placeholder="Mamül Kodu"
          @keydown.enter="getData"
        />
      </b-form-group>
    </b-col>
    <b-col>
      <b-form-group
        label="Kalite Adı"
        label-for="kalite"
      >
        <v-select
          id="kalite"
          v-model="filterParams.kalite"
          :options="filterData.kalite"
          label="label"
          :reduce="item => item.value"
          placeholder="Seçiniz"
        >
          <template v-slot:option="option">
            {{ option.label? option.label : 'Tanımsız' }}
          </template>
        </v-select>
      </b-form-group>
    </b-col>
    <b-col>
      <b-form-group
        label="En"
        label-for="en"
      >
        <v-select
          id="en"
          v-model="filterParams.sen"
          :options="filterData.sen"
          label="label"
          :reduce="item => item.value"
          placeholder="Seçiniz"
        />
      </b-form-group>
    </b-col>
    <b-col>
      <b-form-group
        label="Gramaj"
        label-for="sgr"
      >
        <v-select
          id="sgr"
          v-model="filterParams.sgr"
          :options="filterData.sgr"
          label="label"
          :reduce="item => item.value"
          placeholder="Seçiniz"
        />
      </b-form-group>
    </b-col>
    <b-col cols="auto">
      <b-button
        variant="primary"
        @click="getData"
      >
        <FeatherIcon icon="FilterIcon" />
        Filtrele
      </b-button>
    </b-col>
  </b-row>
</template>
<script>
import {
  BRow, BCol, BFormGroup, BFormInput, BButton,
  VBTooltip,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  name: 'FilterCard',
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BButton,
    vSelect,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    getData: {
      type: Function,
      required: true,
    },
  },
  computed: {
    filterParams() {
      return this.$store.getters['apiProducts/filterParams']
    },
    filterData() {
      return this.$store.getters['apiProducts/filterData']
    },
  },
}
</script>
